import React from "react"

import { Table } from "./../../../../components/Styled"

const InitialVariables = () => {
  return (
    <>
      <section>
        <h3>Başlangıç Değişkenleri</h3>

        <Table className="table is-bordered">
          <tbody>
            <tr>
              <th>Değişken adı</th>
              <th>Tür</th>
              <th>Değer</th>
            </tr>
            <tr>
              <td>
                <code>$white</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#fff"}}></div>
                <code>#fff</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-100</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#fafafa"}}></div>
                <code>#fafafa</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-200</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#f5f5f5"}}></div>
                <code>#f5f5f5</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-300</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#eee"}}></div>
                <code>#eee</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-400</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#e0e0e0"}}></div>
                <code>#e0e0e0</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-500</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#bdbdbd"}}></div>
                <code>#bdbdbd</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-600</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#9e9e9e"}}></div>
                <code>#9e9e9e</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-700</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#757575"}}></div>
                <code>#757575</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-800</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#616161"}}></div>
                <code>#616161</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grey-900</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#424242"}}></div>
                <code>#424242</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$black</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#191919"}}></div>
                <code>#191919</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$turquoise</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#26a69a"}}></div>
                <code>#26a69a</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$blue</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#2196f3"}}></div>
                <code>#2196f3</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$red</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#f44336"}}></div>
                <code>#f44336</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$orange</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#ffca28"}}></div>
                <code>#ffca28</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$yellow</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#ffee58"}}></div>
                <code>#ffee58</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$green</code>
              </td>
              <td>
                <code>color</code>
              </td>
              <td>
                <div style={{"background-color": "#23d160"}}></div>
                <code>#23d160</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-family</code>
              </td>
              <td>
                <code>font-family</code>
              </td>
              <td>
                <code>-apple-system, BlinkMacSystemFont, "Segoe UI", "Inter"</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-size</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>16px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-size-small</code>
              </td>
              <td>
                <code>font-size</code>
              </td>
              <td>
                <code>14px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-1</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>40px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-2</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>32px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-3</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>28px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-4</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>24px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-5</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>20px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$header-6</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>16px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-light</code>
              </td>
              <td>
                <code>font-weight</code>
              </td>
              <td>
                <code>300</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-normal</code>
              </td>
              <td>
                <code>font-weight</code>
              </td>
              <td>
                <code>400</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-medium</code>
              </td>
              <td>
                <code>font-weight</code>
              </td>
              <td>
                <code>500</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-semibold</code>
              </td>
              <td>
                <code>font-weight</code>
              </td>
              <td>
                <code>600</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$font-bold</code>
              </td>
              <td>
                <code>font-weight</code>
              </td>
              <td>
                <code>700</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-columns</code>
              </td>
              <td>
                <code>layout</code>
              </td>
              <td>
                <code>12</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$gap</code>
              </td>
              <td>
                <code>layout</code>
              </td>
              <td>
                <code>16px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-breakpoints(xs)</code>
              </td>
              <td>
                <code>breakpoint</code>
              </td>
              <td>
                <code>0</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-breakpoints(sm)</code>
              </td>
              <td>
                <code>breakpoint</code>
              </td>
              <td>
                <code>576</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-breakpoints(md)</code>
              </td>
              <td>
                <code>breakpoint</code>
              </td>
              <td>
                <code>768</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-breakpoints(lg)</code>
              </td>
              <td>
                <code>breakpoint</code>
              </td>
              <td>
                <code>992</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$grid-breakpoints(xl)</code>
              </td>
              <td>
                <code>breakpoint</code>
              </td>
              <td>
                <code>1200</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$shadow</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>0 0 0 3px rgba(0, 209, 178, 0.25)</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$radius</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>4px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$body-min-width</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>320px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$text-opacity</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>0.8</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$shadow</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>0 0 0 3px rgba(0, 209, 178, 0.25)</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$radius</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>4px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$spacer</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>16px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$transition</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>200ms ease-out</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$disabled</code>
              </td>
              <td>
                <code>generic</code>
              </td>
              <td>
                <code>0.5</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$title-1</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>60px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$title-2</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>48px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$title-3</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>36px</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>$title-4</code>
              </td>
              <td>
                <code>size</code>
              </td>
              <td>
                <code>26px</code>
              </td>
            </tr>
          </tbody>
        </Table>
      </section>
    </>
  )
}

export default InitialVariables